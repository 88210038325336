import React from 'react'
import { Box, Container } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const ContactConfirm = () => {
  return (
    <Container>
    <Box sx={{ mt:"5rem", display: "flex", flexDirection:"column", justifyContent:"center", alignItems:"center" }}>

    <h2>お問い合わせありがとうございました。</h2>
    <p>ご入力いただいた内容は正常に送信されました。</p>
    <p>迅速にご返信いたしますので、少々お待ちください。</p>
    <p>なお、確認のためにメールをお送りしておりますので、受信箱をご確認ください。</p>
    </Box>
    <Button variant="contained" component={Link} to="/" sx={{ width:"30%",mx:"auto", mt: 2, color: "black", display:"flex", flexDirection:"column", justifyContent:"center"}}>
        <Typography
          textAlign="center"
        >
          トップページに戻る
        </Typography>
      </Button>
    </Container>
  );
};

export default ContactConfirm;