import React from "react";
import { useParams } from "react-router-dom";
import worksData from "../data/works_data.json";
import Box from "@mui/material/Box";
import Youtube from "react-youtube";
import { styled } from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";



const CustomP = styled.p`
margin: 2 0;
`;

const DiscographyDetail = () => {

  // const [works, setWorks] = useState([]);
  const { slug } = useParams();
  const works = worksData;
  // useEffect(() => {
  //   const getWorks = async () => {
  //     const response = await fetch("http://jsonplaceholder.typicode.com/posts")
  //       .then((response) => response.json()) // .then
  //       .then((works) => setWorks(works)); // .then
  //   };
  // }, [slug]);

  // const axiosInstance = axios.create({
  //   baseURL: "http://localhost:8000",
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //   },
  // })

  // useEffect(() => {
  //   fetch('https://localhost:8000/api')
  //   .then(response => response.json())
  //   .then(data => console.log(data))
  //   .catch(error => console.error('Error:', error));
  // }, [slug]);

  useEffect(() => {
    axios.get("http://localhost:8000/api/")
      .then(res => {
        const posts = res.data;
        console.log(posts);
        // setState(posts); // これを適切に設定する必要があります
      })
      .catch(err => {
        console.log(err);
      });
  }, [slug]); 

  const opts = {
    // height: "390",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };



  return (
    <Box
      sx={{
        width:{ xs: "90%", sm:"75%", md:"50%"},
        margin: "40px auto",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      <Box mb={"10px"} sx={{ minHeight:"15rem" }}>
        <Youtube videoId={works[slug].youtubeId} opts={opts} />
      </Box>
      <Box ml={"10px"} mb={"10px"}>
        <Typography variant="body1">
        <h2>{works[slug].title}</h2>
        <p>
          歌唱：{works[slug].vocal}
          <br />
          作詞：{works[slug].lyricAuthor}
          <br />
          作曲：{works[slug].musicAuthor}
          <br />
          編曲：{works[slug].arrangeAuthor}
          <br />
          Mix：{works[slug].mixingEngineer}
        </p>
        <p>
          投稿日時：{works[slug].postDate}
        </p>
        <p>{works[slug].description}</p>
      </Typography>
      </Box>
    </Box>
  );
};

export default DiscographyDetail;
