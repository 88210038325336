import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import ReactPaginate from "react-paginate";
import "./../css/paginationStyle.css";




function CategorySelectToggleButtonGroup(props) {
  const { category, filter, handleFilter } = props;

  const ToggleButtonFont = (props) => (
    <Typography
      variant="h4"
      sx={{
        fontSize: { xs: "60%", sm:"120%", md: "160%" },
        fontWeight: 700,
        textAlign: "center",
      }}
    >
      {props.children}
    </Typography>
  );

  return (
    <Box
      marginY={"15px"}
      height={"40px"}
      sx={{ display: "flex", justifyContent: "center"}}
    >
      <ToggleButtonGroup
        value={filter}
        exclusive
        onChange={handleFilter}
        aria-label="text alignment"
      >
        {category.map((category, index) => (
          <ToggleButton key={index} value={category.id} aria-label={category.cat_en} >
            <ToggleButtonFont>{category.cat_jp}</ToggleButtonFont>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
}

const PaginationWithFilter = (props) => {
  const { children, pageCount, handlePageClick, filter, handleFilter, category } = props;

  return (
    <Box>
      <CategorySelectToggleButtonGroup
        category={category}
        filter={filter}
        handleFilter={handleFilter}
      />
      <Box minHeight={"600px"}>{children}</Box>
      <ReactPaginate
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName="paginationContainer justify-center"
        pageClassName="page-item" // li
        pageLinkClassName="page-link"
        previousClassName="page-item" // li
        nextClassName="page-item" // li
        previousLabel={"<"} // a
        previousLinkClassName="previous-link"
        nextLabel={">"} // a
        nextLinkClassName="next-link"
      />
    </Box>
  );
};

export default PaginationWithFilter;
