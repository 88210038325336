import React from "react";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import { styled } from "styled-components";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";

const RequiredIcon = styled.span`
  background-color: orange;
  color: white;
  border-radius: 10px;
  padding: 0 5px;
  margin: 0 5px;
`;

const ErrorP = styled.p`
  font-size: 12px;
  color: red;
`;

const CLabel = styled.label`
  margin: 10px 0;
`;

const CTextArea = styled.textarea`
  width: 100%;
  height: 200px;
  margin: 10px 0;
`;

const SubmitButton = styled(Button)`
  width: 20%;
  height: 60px;
  background-color: orange;
  color: white;
  border: none;
`;

const Form = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    getValues,
  } = useForm();

  const onSubmit = (data) => console.log(data);

  const [deadline, setDeadline] = useState(new Date());

  const HandleDeadline = (newDeadline) => {
    setDeadline(newDeadline);
    console.log(deadline);
  };

  return (
    <Paper
      sx={{
        width: { xs: "90%", md: "80%" },
        padding: { xs: "20px", sm: "40px", md: "60px" },
        margin: "60px auto",
      }}
      elevation={3}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          width: "100%",
          // margin: {xs:"0 auto", md:"60px auto"},
          mt: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CLabel htmlFor="name">
          <RequiredIcon>必須</RequiredIcon>
          <Typography variant="h5">お名前</Typography>
        </CLabel>
        <input
          id="name"
          {...register("name", { required: "名前を入力してください。" })}
        />
        <ErrorP>{errors.name?.message}</ErrorP>
        <CLabel htmlFor="email">
          <RequiredIcon>必須</RequiredIcon>
          <Typography variant="h5">メールアドレス</Typography>
        </CLabel>
        <input
          id="email"
          {...register("email", {
            required: "メールアドレスを入力してください",
            onBlur: () => {
              if (getValues("email_confirmation")) {
                trigger("email_confirmation");
              }
            },
            pattern: {
              value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
              message: "入力形式がメールアドレスではありません。",
            },
          })}
        />
        <ErrorP>{errors.email?.message}</ErrorP>
        <CLabel htmlFor="confirmEmail">
          <RequiredIcon>必須</RequiredIcon>
          <Typography variant="h5">
            確認のためメールアドレスを再入力してください。
          </Typography>
        </CLabel>
        <input
          id="confirmEmail"
          {...register("confirmEmail", {
            required: "メールアドレスが一致していません。",
            validate: (value) =>
              value === watch("email") || "メールアドレスが一致していません。", // watch()を使うことで、他のinputの値を参照できる。
          })}
        />
        <ErrorP>{errors.confirmEmail?.message}</ErrorP>
        <CLabel>
          <RequiredIcon>必須</RequiredIcon>
          <Typography variant="h5">カテゴリ</Typography>
        </CLabel>
        <CLabel>
          <select
            name="selectedRequest"
            {...register("selectedRequest", {
              required: true,
              validate: (value) => value > -1 || "選択肢を選んでください"
            })}
          >
            <option value={-1}>選択してください</option>
            <option value={0}>音楽制作のご依頼/お見積り</option>
            <option value={1}>Mixや楽器演奏、耳コピ作成のご依頼</option>
            <option value={2}>楽曲の使用許可/報告</option>
            <option value={3}>メディア出演依頼</option>
            <option value={4}>その他</option>
          </select>
        </CLabel>
        <ErrorP>{errors.selectedRequest && errors.selectedRequest?.message}</ErrorP>
        <CLabel>
          <Typography variant="h5">希望納期</Typography>
        </CLabel>
        <input
          type="date"
          name="deadline"
          value={deadline}
          onChange={(e) => setDeadline(e.target.value)}
        />

        <CLabel htmlFor="budget">
          <Typography variant="h5">おおよそのご予算</Typography>
        </CLabel>
        <input id="budget" type="text" {...register("budget")} />

        <CLabel htmlFor="message">
          <RequiredIcon>必須</RequiredIcon>メッセージ
        </CLabel>
        <CTextArea
          name="messagetext" 
        {...register("messagetext", { required: "メッセージを入力してください。" })} />
        <ErrorP>{errors.messagetext && errors.messagetext?.message}</ErrorP>
        <Box sx={{ mt: "10px", display: "flex", justifyContent: "center" }}>
          <SubmitButton
            variant="contained"
            color="primary"
            type="submit"
            // component={Link}
            // to={`/Contact/confirm`}
          >
            送信
          </SubmitButton>
        </Box>
      </Box>
    </Paper>
  );
};

export default Form;
