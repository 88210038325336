import { Container, display, margin } from "@mui/system";
import { Box } from "@mui/system";
import PageTitle from "./PageTitle";
import PaginationWithFilter from "./PaginationWithFilter";
import { useState } from "react";
import NewsList from "./NewsList";
import posts from "../data/news_data.json";
import newsCategory from "../data/news_category.json";
import FeedinTransition from "../utils/feedinTransition";



function NewsTopLine() {
  return (
    <Box
      sx={{
        width: { sm: "100%", md: "50%" },
        margin: { sm: "0 0 20px 0", md: "0 auto" },
        alignItems: "center",
        borderBottom: 1,
        display: "flex",
      }}
    ></Box>
  );
} 

// postsの中身は実際にはDBから取得する
export default function News() {
  const [news, setNews] = useState(posts);
  const itemsPerPage = 8;
  const [itemsOffset, setItemsOffest] = useState(0);
  const [filter, setFilter] = useState(0);

  const endOffset = itemsOffset + itemsPerPage;

  // worksの中からfilterに合致するものを抽出し、itemsOffsetからendOffsetまでを抽出する。
  const currentNews = news
    .filter((anews) => {
      if (filter === 0) {
        return true;
      } else {
        return anews.category === filter;
      }
    })
    .slice(itemsOffset, endOffset);

  const pageCount = Math.ceil(news.length / itemsPerPage);

  const handlePageClick = (data) => {
    let selected = data.selected;
    let newOffset = (selected * itemsPerPage) % news.length;
    setItemsOffest(newOffset);
  };

  const handleFilter = (event, newFilter) => {
    if (newFilter === null) {
      newFilter = 0;
    }
    setFilter(newFilter);
  };

  return (
    <FeedinTransition>
    <Container>
      <PageTitle>News</PageTitle> 

      <PaginationWithFilter
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            filter={filter}
            handleFilter={handleFilter}
            category={newsCategory}
          >
        <Box
      sx={{
        width: { xs: "80%", md: "50%" },
        display: "flex",
        flexDirection: "column",
        margin : "0 auto",
      }}
    >
            <NewsList currentNews={currentNews}/>
            </Box>
          </PaginationWithFilter>

    </Container>
    </FeedinTransition>
  );
}
