import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import "../index.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

const pages = ["news", "about", "discography", "lineup", "contact"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Container>
          <Toolbar width="100%" style={{ justifyContent: "space-between" }}>
            {/* For PC */}
            <Typography
              variant="h5"
              style={{ textDecoration: "none"}}
              component={Link}
              to="/"
              sx={{
                color: "brown",
                fontStyle: "normal",
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                textDecoration: "none",
                fontFamily: "fot-tsukuardgothic-std, sans-serif",
              }}
            >
              メリッサ / 卯月奏 Official Website
            </Typography>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  component={Link}
                  to={`/${page}`}
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "black", display: "block"}}>
                  <Typography
                    textAlign="center"
                    sx={{

                      fontFamily: "fot-tsukuardgothic-std, sans-serif",
                      fontSize: { md: "0.9rem", lg: "1.0rem" },
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: '-3px',
                        left: 0,
                        width: '100%',
                        height: '2px',
                        background: '#000000',
                        transform: 'scale(0, 1)',
                        transformOrigin: 'left top',
                        transition: 'transform .3s',
                      },
                      '&:hover::after': {
                        transform: 'scale(1, 1)',

                      },
                    }}
                  >
                    {page}
                  </Typography>
                </Button>
              ))}
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                href="https://twitter.com/LockP_melissa"
                target="_blank"
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <img width="16px" src="./images/logo-black.png" alt="x_icon" />
              </IconButton>
              <IconButton
                href="https://www.youtube.com/@user-kj6gx6tw9l"
                target="_blank"
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <YouTubeIcon />
              </IconButton>
              <IconButton
                href="https://lockp-melissa.booth.pm/"
                target="_blank"
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
               <img width="22px" src="./images/booth_logo_icon_r.png" alt="booth_icon" />
              </IconButton>
            </Box>

            {/* For smart phone and tablet  */}
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to={"/"}
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                fontStyle: "normal",
                fontSize: { xs: "90%", sm: "100%" },
                fontWeight: 700,
                color: "brown",
                textDecoration: "none",
              }}
            >
              メリッサ / 卯月奏 Official Website
            </Typography>

            {/* ハンバーガーメニュー */}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography
                      variant="h5"
                      textAlign="center"
                      component={Link}
                      to={`/${page}`}
                      style={{ textDecoration: "none" }}
                      color="brown"
                    >
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default ResponsiveAppBar;
