import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import PageTitle from "./PageTitle";
import worksData from "../data/works_data.json";
import WorkList from "./WorkList";
import PaginationWithFilter from "./PaginationWithFilter";
import discographyCategory from "../data/discography_category.json";
import { motion } from "framer-motion";
import FeedinTransition from "../utils/feedinTransition";

export default function Discography() {
  const [works, setWorks] = useState(worksData);

  const itemsPerPage = 8;
  const [itemsOffset, setItemsOffest] = useState(0);
  const [filter, setFilter] = useState(0);

  // useEffect(() => {
  //   const getWorks = async () => {
  //     const response = await fetch("http://jsonplaceholder.typicode.com/posts")
  //       .then((response) => response.json()) // .then
  //       .then((works) => setWorks(works)); // .then
  //   };
  //   getWorks();
  // }, []);

  const endOffset = itemsOffset + itemsPerPage;

  // worksの中からfilterに合致するものを抽出し、itemsOffsetからendOffsetまでを抽出する。
  const currentWorks = works
    .filter((work) => {
      if (filter === 0) {
        return true;
      } else {
        return work.category === filter;
      }
    })
    .sort((a,b) => {
      if(a.postDate < b.postDate) return 1;
      if(a.postDate > b.postDate) return -1;
      return 0;
    })
    .slice(itemsOffset, endOffset);

  const pageCount = Math.ceil(works.length / itemsPerPage);

  const handlePageClick = (data) => {
    let selected = data.selected;
    let newOffset = (selected * itemsPerPage) % works.length;
    setItemsOffest(newOffset);
  };

  const handleFilter = (event, newFilter) => {
    if (newFilter === null) {
      newFilter = 0;
    }
    setFilter(newFilter);
  };

  return (
    <FeedinTransition>
      <Container>
        <PageTitle>Discography</PageTitle>
        <Box
          sx={{
            margin: "40px auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box width={"80%"}>
            <PaginationWithFilter
              pageCount={pageCount}
              handlePageClick={handlePageClick}
              filter={filter}
              handleFilter={handleFilter}
              category={discographyCategory}
            >
              <WorkList currentWorks={currentWorks} />
            </PaginationWithFilter>
          </Box>
        </Box>
      </Container>
    </FeedinTransition>
  );
}
