import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";

import Home from "./components/Home";
import News from "./components/News";
import About from "./components/About";
import Discography from "./components/Discography";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import Article from "./components/Article";
import DiscographyDetail from "./components/DiscographyDetail";
import ContactConfirm from "./components/ContactConfirm";
import { AnimatePresence } from "framer-motion";
import { responsiveFontSizes } from "@mui/material/styles";
import Lineup from "./components/Lineup";
import Campaign1 from "./components/Campaign1";

let theme = createTheme({
  palette: {
    primary: {
      main: "#ffe0b2",
    },
    secondary: {
      main: "#fb8c00",
    },
  },


  typography: {
    fontSize: 14,
    '@media (min-width:600px)': {
      fontSize: 8,  // 600px以上の画面幅でのフォントサイズ
    },
    '@media (min-width:900px)': {
      fontSize: 10,  // 960px以上の画面幅でのフォントサイズ
    },
    fontFamily: "fot-tsukuardgothic-std, sans-serif",
    
    // h1: {
    //   fontWeight: 500,
    //   lineHeight: 1.2,
    //   // 他のh1のスタイルプロパティもここで設定できます
    // },
    // h2: {
    //   fontSize: "1.5rem",
    //   fontWeight: 400,
    //   lineHeight: 1.3,
    //   // 他のh2のスタイルプロパティもここで設定できます
    // },
    // h3: {
    //   fontSize: "1.2rem",
    //   fontWeight: 400,
    //   lineHeight: 1.4,
    //   // 他のh3のスタイルプロパティもここで設定できます
    // },
    // h4: {
    //   fontSize: "1rem",
    //   fontWeight: 400,
    //   lineHeight: 1.5,
    //   // 他のh4のスタイルプロパティもここで設定できます
    // },
    // h5: {
    //   fontSize: "0.8rem",
    //   fontWeight: 400,
    //   lineHeight: 1.6,
    //   // 他のh5のスタイルプロパティもここで設定できます
    // },
    // h6: {
    //   fontSize: "0.6rem",
    //   fontWeight: 400,
    //   lineHeight: 1.7,
    //   // 他のh6のスタイルプロパティもここで設定できます
    // },
    // p: {
    //   fontSize: "0.5rem",
    //   fontWeight: 400,
    //   lineHeight: 0.5,
    // },
    // body1: {
    //   fontSize: "0.5rem",
    //   fontWeight: 400,
    //   lineHeight: 0.5,
    // },
    // body2: {
    //   fontSize: "0.5rem",
    //   fontWeight: 400,
    //   lineHeight: 0.5,
    // },
    // 他のpのスタイルプロパティもここで設定できます

    // 他の見出し要素も同様に設定可能
    // 例: h4, h5, h6, subtitle1, subtitle2, body1, body2, caption, button
  },
});

theme = responsiveFontSizes(theme);

function NotFound() {
  return <h2>Not Found</h2>;
}

export default function App() {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <AnimatePresence>
          <ScrollTop />
          <Header />
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/news/:slug" element={<Article />} />
            <Route path="/news" element={<News />} />
            <Route path="/about" element={<About />} />
            <Route path="/discography/:slug" element={<DiscographyDetail />} />
            <Route path="/discography" element={<Discography />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/lineup" element={<Lineup />} />
            <Route path="/contact/confirm" element={<ContactConfirm />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/campaign/1" element={<Campaign1 />} />
            <Route element={<NotFound />} />
          </Routes>
          <Footer />
      </AnimatePresence>
    </ThemeProvider>
  );
}
