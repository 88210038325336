import React from "react";
import { motion } from "framer-motion";

const FeedinTransition = ({ children }) => {
  const feedin = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.3,
        delayChildren: 0.3,
        staggerChildren: 0.2,
      }
    },

  };

  return (
    <div>
      <motion.div initial="initial" animate="animate" variants={feedin}>
        {children}
      </motion.div>
    </div>
  );
};

export default FeedinTransition;
