import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import newsCategory from "../data/news_category.json";
import styled from "styled-components";


const CLink = styled(Link)`
  &:hover {
    opacity: 0.7;
    color: brown;
    transition: color 0.5s;
}
`;


export default function NewsHeadline(props) {
  const { key, content} = props;
  const isTop = key === 0 ? 1 : 0;
  return (

    <Box
      sx={{
        width: "100%",
        // margin: { sm: "0 0 20px 0", md: "0 auto" },
        borderBottom: 1,
        borderTop: isTop,
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center",
      }}
    >
      {/* 投稿日時とカテゴリ（左側 縦） */}
      <Box
        sx={{
          width: "25%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* 投稿日時 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              margin: "5px auto",
              fontSize: {xs:"65%", md:"85%"},
            }}
          >
            {content.date}
          </Typography>
        </Box>
        {/* カテゴリ */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              margin: "5px auto",
              fontSize: {xs:"65%", md:"85%"},
            }}
          >
            {newsCategory.find(item => item.id === content.category)?.cat_jp}
          </Typography>
        </Box>
      </Box>
      {/* 記事タイトル 右側*/}
      <Box
        sx={{
          width: "75%",
          marginleft: "20px",
        }}
      >
        <CLink style={{textDecoration:"none"}} to={`/news/${content.slug}` }>
          <Typography
            variant="h6"
            sx={{
              margin: "5px auto",
              fontSize: "90%",
              fontWeight: 300,
            }}
          >
            {content.title}
          </Typography>
        </CLink>
      </Box>
    </Box>
  );
}
