import React from "react";
import NewsHeadline from "./NewsHeadline";
import { Box } from "@mui/system";

const NewsList = (prop) => {
  const { currentNews} = prop;
  const reversedArr = [...currentNews].reverse()

  return (
  <>
      {reversedArr.map((news, index) => (
        <NewsHeadline key={index} content={news} />
      ))}
    </>
  );
};

export default NewsList;
