import { Box } from "@mui/system";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../css/ImageSlider.css";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Contact from "./Contact";
import { Link } from "react-router-dom";
import NewsList from "./NewsList";
import { useState } from "react";
import posts from "./../data/news_data.json";
import { Typography } from "@mui/material";
import FeedinTransition from "../utils/feedinTransition";
import styled from "styled-components";
import Campaign1 from "./Campaign1";

const CButton = styled(Button)`
  transition: 1s all ease;

  &:hover {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }
`;

function MultipleSlider(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // slidesToShowよりもimgの数が大きくないとdotsは表示されない
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {props.sliderImg.map((prop, index) => (
          <Box key={index}>
            <Link target={prop.target} to={prop.link}>
              {/* <a href={prop.link} target="_blank" rel="noopener noreferrer"> */}
              <img src={prop.image} alt={prop.alt} />
              {/* </a> */}
            </Link>
          </Box>
        ))}
      </Slider>
    </>
  );
}

export default function Home() {
  const [news, setNews] = useState(posts);
  const sliderImages = [
    {
      link: "https://www.youtube.com/watch?v=qYepsYj5VjM",
      image: "./images/スニーカー カルーセル.png",
      alt: "slider1",
      target: "_blank",
    },
    {
      link: "./news/open-official-website",
      image: "./images/オープン カルーセル.png",
      alt: "slider2",
      target: "",
    },
    {
      link: "./campaign/1",
      image: "./images/終了.png",
      alt: "slider3",
      target: "",
    },
  ];

  const newNews = news.slice(0, 3);

  return (
    <FeedinTransition>
      <Box>
        <MultipleSlider sliderImg={sliderImages} />
      </Box>
      <Container>
        <Box
          sx={{
            mt: 3,
            mb: 3,
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // ここでレスポンシブ対応
            justifyContent: "space-between",
            rowGap: "1rem",
            columnGap: "1rem",
          }}
        >
          {/* 左側 News */}
          <Box
            sx={{
              width: { xs: "90%", md: "90%" },
              flexGrow: 1,
              margin: "0 auto",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                margin: "15px auto",
                textAlign: "center",
              }}
            >
              News
            </Typography>
            <NewsList currentNews={newNews} />
            <Link style={{ textDecoration: "none" }} to={`/news`}>
              <CButton
                variant="contained"
                sx={{ margin: "0 auto", mt: 3, mb: 3, display: "block" }}
              >
                View More
              </CButton>
            </Link>
          </Box>
          {/* 右側 About */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                margin: "15px auto",
                textAlign: "center",
              }}
            >
              About
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                width="120px"
                src="./images/uzuki_face.png"
                alt="uzuki_kanade"
              />
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                メリッサ / 卯月奏
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 300,
                  textAlign: "center",
                }}
              >
                作曲家/ボカロP
              </Typography>
              <Link style={{ textDecoration: "none" }} to={`/about`}>
                <CButton
                  variant="contained"
                  sx={{ margin: "0 auto", mt: 3, mb: 3, display: "block" }}
                >
                  View More
                </CButton>
              </Link>
            </Box>
          </Box>
        </Box>
        {/* 下側 Contact */}
        <Box
          sx={{
            mt: 3,
            mb: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                margin: "15px auto",
                textAlign: "center",
              }}
            >
              Contact
            </Typography>
          </Box>
          <Box>
            <Link style={{ textDecoration: "none" }} to="/contact">
              <CButton variant="contained">
                楽曲制作などのお問い合わせはこちらから
              </CButton>
            </Link>
          </Box>
        </Box>
      </Container>
    </FeedinTransition>
  );
}
