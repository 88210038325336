import React from "react";
import { Box } from "@mui/system";
import FeedinTransition from "../utils/feedinTransition";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Typography } from "@mui/material";

const CButton = styled(Button)`
  transition: 1s all ease;
  cornerradius: 50px;
  &:hover {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  }
`;

const Campaign1 = () => {
  return (
    <FeedinTransition>
      <div
        style={{
          backgroundImage: `url(/images/pastel_00002.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",

          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Box
            sx={{
              width: "90%",
              margin: "20px auto",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <img
              src="/images/Webサイト 作曲キャンペーンバナー メイン.png"
              alt="banner"
              width="100%"
            />
          </Box>
          {/* <Link style={{ textDecoration: "none" }} to={`/contact`}>
            <Button
              variant="contained"
              sx={{
                width: "350px",
                height: "60px",
                margin: "0 auto",
                mt: "50px",
                mb: "30px",
                display: "block",
                fontWeight: "bold",
                fontSize: "1.2rem",
                backgroundColor: "#ffff89",
              }}
            >
              ご相談・ご依頼はこちらから！
            </Button>
          </Link> */}
          <Box
            sx={{
              mb: "60px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: "ヒラギノ角ゴシック ProN",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              定員に達したためキャンペーンは3/11に終了いたしました。
            </Typography>
          </Box>
          {/* <Box sx={{ width: { xs: "100%", md: "100%" }, textAlign: "center" }}>
            <img
              src="/images/campaign_content.png"
              alt="campaign_content"
              width="100%"
            />
          </Box> */}

          {/* <Link style={{ textDecoration: "none" }} to={`/contact`}>
            <Button
              variant="contained"
              sx={{
                width: "350px",
                height: "60px",
                margin: "0 auto",
                mt: "30px",
                mb: "30px",
                display: "block",
                fontWeight: "bold",
                fontSize: "1.2rem",
                backgroundColor: "#ffff89",
              }}
            >
              ご相談・ご依頼はこちらから！
            </Button>
          </Link> */}
        </Container>
      </div>
    </FeedinTransition>
  );
};

export default Campaign1;
