import ReactMarkdown from "react-markdown";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import matter from "gray-matter";
import { Buffer } from "buffer";
import { Link, useLocation, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import newsCategory from "../data/news_category.json";
import FeedinTransition from "../utils/feedinTransition";
import {
  XIcon,
  TwitterShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
} from "react-share";

// @ts-ignore
window.Buffer = Buffer;

const HeadContainer = styled.div`
  font-family: fot-tsukuardgothic-std, sans-serif;
  padding: 30px;

  border-radius: 4px;
  text-align: left;

  h1 {
    font-size: 36px;
    color: #333;
    margin-top: 8px;
    margin-bottom: 32px;
    font-weight: 700;
    border-color: #8b4513;
  }

  p {
    font-size: 16px;
    color: #555;
  }
`;

const MarkdownContainer = styled.div`
  font-family: fot-tsukuardgothic-std, sans-serif;
  margin: 10px auto;
  padding: 2rem;
  text-align: left;

  h1 {
    font-size: 20px;
    color: #333;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 16px;
  }

  p {
    font-size: 18px;
    color: #555;
    margin-bottom: 8px;
  }

img {
  display: flex;
  margin: 0 auto;

`;

export default function Article(props) {
  const [markdown, setMarkdown] = useState("");

  const slug = useParams();

  useEffect(() => {
    fetch(`/markdown/${slug.slug}.md`)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);
  const { data, content } = matter(markdown);

  const location = useLocation();
  const rootDomain = "https://www.melissa-lockp.com";

  return (
    <FeedinTransition>
    <Box
      sx={{
        width: { xs: "90%", md: "50%" },
        margin: "50px auto",
      }}
    >
      <Paper elevation={3}>
        <HeadContainer>
          <Typography variant="p" sx={{ fontSize: "75%" }}>
            カテゴリ：
            {newsCategory.find((item) => item.id === data.category)?.cat_jp} /
            投稿日時：{data.postdate} / 更新日時：{data.modifydate}
          </Typography>
          <Typography variant="h1" sx={{ fontWeight: 700 }}>
            {data.title}
          </Typography>
          {/* <img
            src={data.image}
            width="100%"
            loading="lazy"
          /> */}
        </HeadContainer>
        <MarkdownContainer>
          <ReactMarkdown>{content}</ReactMarkdown>
        </MarkdownContainer>
        <Box  sx={{width:"200px",margin: "0 auto", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
        <TwitterShareButton title={data.title} url={rootDomain + location.pathname} hashtags={data.tags}>
          <XIcon size={32} round={true}/>
        </TwitterShareButton>
        <FacebookShareButton title={data.title} url={rootDomain + location.pathname}>
          <FacebookIcon size={32} round={true}/>
        </FacebookShareButton>
        <LineShareButton title={data.title} url={rootDomain + location.pathname}>
          <LineIcon size={32} round={true}/>
        </LineShareButton>
        </Box>
        <Typography
          variant="h6"
          sx={{
            margin: "20px auto",
            textAlign: "center",
            paddingBottom: "20px",
          }}
        >
          <Link to="/news">一覧に戻る</Link>
        </Typography>
      </Paper>
    </Box>
    </FeedinTransition>
  );
}
