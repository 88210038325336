import { Box, Typography } from "@mui/material";

const TypographyH2 = (props) => {
  return (
    <Typography
      variant="h4"
      sx={{
        fontWeight: "bold",
        background: "#ffc0cb",
        boxShadow: "0px 0px 0px 5px #ffc0cb",
        border: "dashed 2px white",
        padding: "0.2em 1em",
        textAlign: "center",
        width: "60%",
        margin: "0 auto",
        marginBottom: "30px",
      }}
    >
      {props.children}
    </Typography>
  );
};

const TypographyH3 = (props) => {
  return (
    <Typography
      variant="h5"
      sx={{
        fontWeight: "bold",
        color: "#ff69b4", /*文字色*/
        /*線の種類（点線）2px 線色*/
        borderBottom: "dashed 2px #ff69b4",
        margin: "20pt auto",
        textAlign: "center",
        width: "70%",
      }}
    >
      {props.children}
    </Typography>
  );
};

export default function CopyMusic() {
  return (
    <Box sx={{ width: "100%", marginTop: "30px" }}>
      <TypographyH2>耳コピ制作</TypographyH2>
      <Box sx={{ width: "70%", textAlign: "center", margin: "0 auto" }}>
        <TypographyH3>確かなクオリティの音源をあなたに！</TypographyH3>
        <Box sx={{ width: "100%", margin: "0 auto" }}>
        <Typography variant="body1">
          どうしても歌ってみたを出したいけど音源がない…
          <br />
          でもカラオケみたいな薄い音源は嫌…
          <br />
          <br />
          そんな時は耳コピ制作をご利用ください！
          <br />
          作曲家である私が普段から使用する高級音源や生演奏を駆使して、確かなクオリティの音源をお届けします！
        </Typography>
        </Box>
        <TypographyH3>制作の流れ</TypographyH3>
        <Typography variant="body1">
          下記お問い合わせフォームから見積もり相談
          <br />
          ↓<br />
          著作権確認・キー確認・お見積(Discord通話)
          <br />
          ↓<br />
          耳コピ制作
          <br />
          ↓<br />
          ボーカルレコーディング(オプション)
          <br />
          ↓<br />
          Mix・マスタリング
          <br />
          ↓<br />
          納品
          <br />
        </Typography>
        <TypographyH3>料金</TypographyH3>
        <Box sx={{ width: "100%", margin: "0 auto" }}>
          <Typography variant="body1">
            耳コピ制作（１コーラス）：¥15,000～
            <br />
            耳コピ制作（フルコーラス）：¥25,000～
            <br />
            リテイク：2回まで無料、3回目からは1回につき+5000円
            <br />
            ボーカルMixオプション:+¥5000
            <br />
            <br />
            ※上記はあくまで目安です。
            <br />
            制作難易度・繁忙度・法人or個人などによって上下いたしますので、まずはお気軽にお見積りのご相談からお願いします。
          </Typography>
        </Box>
        <TypographyH3>注意事項</TypographyH3>
        <Box sx={{ width: "100%", margin: "0 auto" }}>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", margin: "20px 30px" }}
          >
            ■楽曲の著作権について
          </Typography>
          <Box sx={{ width: "100%", margin: "0 auto" }}>
            <Typography variant="body1">
              権利関係がクリアになっている楽曲のみ制作可能です。
              <br />
              JASRACやNEXTONEの依託楽曲であり、それらと包括契約を行っているプラットフォームへの投稿であれば著作権上問題ありません。
              <br />
              ただし、著作者人格権については著作者が保有しているため、侵害しないようお客様の方でご対応ください。
              <br />
              著作権者から申し立てがあった場合、当方は一切責任を負いません。
              <br />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
