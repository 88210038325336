import { Container } from "@mui/system";
import PageTitle from "./PageTitle";
import Form from "./Form";
import FeedinTransition from "../utils/feedinTransition";
import Box from "@mui/material/Box";
import OriginalMusic from "./OriginalMusic";
import CopyMusic from "./CopyMusic";


export default function Contact() {
  return (
    <FeedinTransition>
      <Container>
        <PageTitle>Contact</PageTitle>
         <Box sx={{ width: "100%", textAlign: "center" }}>
         <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeIInp5xOR0jgIg2ElFuJEevTwMB4_MPn5yOiUiQ-0VObNKhw/viewform?embedded=true" width="100%" height="2200" frameborder="no" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
          {/* <img
            width="80%"
            src="./images/オリジナル楽曲制作.png"
            alt="contact"
          /> */}
        </Box>
        {/* <Form /> */}
      </Container>
    </FeedinTransition>
  );
}
