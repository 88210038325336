import React from 'react'
import { Typography } from '@mui/material'

const PageTitle = (props) => {
  return (
    <Typography
    variant="h2"
    sx={{
      fontFamily: "fot-tsukuardgothic-std, sans-serif",
      fontWeight: 700,
      margin: "40px auto",
      textAlign: "center",
    }}
  >
    {props.children}
  </Typography>
  )
}

export default PageTitle