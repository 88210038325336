import { Box, Typography } from "@mui/material";


const TypographyH2 = (props) => {
  return (
    <Typography
      variant="h4"
      sx={{
        fontWeight: "bold",
        background: "#dfefff",
        boxShadow: "0px 0px 0px 5px #dfefff",
        border: "dashed 2px white",
        padding: "0.2em 1em",
        textAlign: "center",
        width: "60%",
        margin: "0 auto",
        marginBottom: "30px",
      }}
    >
      {props.children}
    </Typography>
  );
};

const TypographyH3 = (props) => {
  return (
    <Typography
      variant="h5"
      sx={{
        fontWeight: "bold",
        color: "#6594e0" /*文字色*/,
        /*線の種類（点線）2px 線色*/
        borderBottom: "dashed 2px #6594e0",
        margin: "30pt auto",
        textAlign: "center",
        width: "70%",
      }}
    >
      {props.children}
    </Typography>
  );
};



export default function OriginalMusic() {
  return (
    <Box sx={{ width: "100%", marginTop: "30px" }}>
          <TypographyH2>オリジナル楽曲制作</TypographyH2>
          <Box sx={{ width: "80%", textAlign: "center",margin: "0 auto"  }}>
            <TypographyH3>
              あなたの軌跡と想いを音楽で伝えましょう！
            </TypographyH3>
            <Box sx={{ width: "100%", margin: "0 auto" }}>
            <Typography variant="body1">
              自分の想いをファンに伝えたい、もっと有名になりたい、音楽ライブをやりたい…
              <br />
              理由は何でも大丈夫！あなたの大きな一歩をオリジナル楽曲制作で全力サポートします！！
            </Typography>
            <TypographyH3>制作の流れ</TypographyH3>
            <Typography variant="body1">
              下記お問い合わせフォームから見積もり相談
              <br />
              ↓<br />
              ヒアリング・音域確認・お見積(Discord通話)
              <br />
              ↓<br />
              ワンコーラスデモ提出・イメージ確認
              <br />
              ↓<br />
              フルコーラス歌詞・メロディ確定
              <br />
              ↓<br />
              アレンジ作成・ボーカルレコーディング(並行)
              <br />
              ↓<br />
              Mix・マスタリング
              <br />
              ↓<br />
              納品
              <br />
            </Typography>
            </Box>
            <TypographyH3>料金</TypographyH3>
            <Box sx={{ width: "100%", margin: "0 auto" }}>
              <Typography variant="body1">
                オリジナル楽曲制作（１コーラス）：¥50,000～
                <br />
                オリジナル楽曲制作（フルコーラス）：¥80,000～
                <br />
                リテイク：2回まで無料、3回目からは1回につき+5000円
                <br />
                著作権譲渡契約：応相談
                <br />
                <br />
                ※上記はあくまで目安です。
                <br />
                制作難易度・繁忙度・法人or個人などによって上下いたしますので、まずはお気軽にお見積りのご相談からお願いします。
              </Typography>
            </Box>
            <TypographyH3>注意事項</TypographyH3>
            <Box sx={{ width: "100%", margin: "0 auto"}}>
              <Typography
                variant="h5"
                sx={{ margin: "20px 30px" }}
              >
                ■著作権の譲渡について
              </Typography>
              <Box sx={{ width: "100%", margin: "0 auto" }}>
                <Typography variant="body1">
                  基本的には著作権譲渡を行いませんが、オプションで著作権譲渡を行うことも可能です。
                  <br />
                  著作権譲渡を行わない場合でも、商用利用や音源の配布、著作者人格権を著しく損なわない程度の編集等は全て行っていただいて構いません。
                  <br />
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "100%", margin: "0 auto" }}>
              <Typography
                variant="h5"
                sx={{ margin: "20px 30px" }}
              >
                ■契約について
              </Typography>
              <Box sx={{ width: "100%", margin: "0 auto" }}>
                <Typography variant="body1">
                  お見積りの際には契約書を作成し、双方の同意の元で制作を進めていきます。
                  <br />
                  お支払いは原則口座振込のみとなります。
                  <br />
                  基本的に前払いか前金+後払いとなりますが、ご相談に応じて後払いにすることも可能です。
                  <br />
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
  )
}