import React from 'react';
import Container from 'react-bootstrap/Container';
import Toolbar from '@mui/material/Toolbar';

export default function Footer() {
  return (
<>
<footer className="footer">
  <Container>
    <Toolbar sx={{justifyContent: 'center', }}>
    <small className="">&copy; 2023 Melissa / Uzuki Kanade </small>
    </Toolbar>
  </Container>
</footer>
</>
  );
}