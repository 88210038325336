import { Container } from "@mui/system";
import PageTitle from "./PageTitle";
import Paper from "@mui/material/Paper";
import FeedinTransition from "../utils/feedinTransition";
import { Typography } from "@mui/material";

export default function Blog() {
  return (
    <FeedinTransition>
    <Container sx={
      {textAlign:"center"}
    }>
      <PageTitle>Blog</PageTitle>
      <Typography variant="h5">
      <h2>楽曲解説や音楽理論、日常などを綴るブログはnoteにて公開中！</h2>
      </Typography>
      <a href="https://note.com/uzuki_kanade" target="_blank" rel="noopener noreferrer">
      <Paper
        elevation={3}
        sx={{
          bgcolor: "#fffaf0",
          padding: "20px",
          width: { sm: "100%", md: "70%" },
          margin: "0 auto",
          textAlign: "center",
          lineHeight: "2rem",
          marginTop: "50px",
        }}
      >
        <img width="50%" src="./images/note_logo.png" alt="note_logo" />
      </Paper>
      </a>
    </Container>
    </FeedinTransition>
  );
}
