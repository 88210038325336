import React, { useEffect } from "react";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import VariantTransition from "../utils/VariantTransition";

const ZoomingImg = styled.img`
  max-width: "100%";
  cursor: pointer;
  overflow: hidden;
  &:hover {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    transform: translateY(-5px);
    transition-duration: 0.2s;
  }
`;

function DisplayJacket(props) {
  const CategoryDict = {
    title: {
      1: "シングル",
      2: "アルバム",
      3: "動画",
      4: "楽曲提供",
      5: "その他",
    },
    color: {
      1: "pink",
      2: "indigo",
      3: "teal",
      4: "orange",
      5: "gray",
    },
  };

  return (
    <Paper elevation={3}>
      <Box key={props.id}>
        <Link to={`/discography/${props.id}`}>
          <ZoomingImg width={"100%"} src={props.image} alt={props.title} />
        </Link>
        <Box sx={{ display: { xs: "none", sm: "flex" }, borderTop: "1px solid" }}>
          <Box
            sx={{
              width: "30%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "4.0rem",
              borderRight: "1px dashed",
            }}
          >
            <Box
              sx={{
                bgcolor: CategoryDict.color[props.category],
                width: "80%",
                padding: { xs:"10px auto", sm: "2px auto" },
                marginRight: "5px",
                borderRadius: "4px",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "3.0rem",

              }}
            >
              <Typography
                variant="body1"
                color="white"
                sx={{
                  fontSize: "70%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {CategoryDict.title[props.category]}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection:"column", width: "70%" }}>
            <Box sx={{ borderBottom:"1px dashed"}}>
              <Typography variant="body1" sx={{fontSize:"70%", marginLeft: "1rem"}}>
                {props.postDate}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent:"center", alignItems: "center", flexGrow:1}}>
              <Typography variant="body1" sx={{
                fontWeight: "bold", margin: "auto", textAlign:"center"}}>{props.title}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

const WorkList = (props) => {
  const { currentWorks } = props;

  return (
    <Grid container spacing={2}>
      {currentWorks.map((work) => (
        <Grid key={work.id} item xs={6} md={3}>
          <DisplayJacket key={work.id} {...work} />
        </Grid>
      ))}
    </Grid>
  );
};

export default WorkList;
