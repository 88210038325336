import React from "react";
import OriginalMusic from "./OriginalMusic";
import CopyMusic from "./CopyMusic";
import Lesson from "./Lesson";
import { Box } from "@mui/system";
import PageTitle from "./PageTitle";
import FeedinTransition from "../utils/feedinTransition";

const Lineup = () => {
  return (
    <FeedinTransition>
    <Box sx={{ width: "100%" }}>
            <PageTitle>Line up</PageTitle> 
          <Box sx={{ width: "100%", marginTop: "50px", marginBottom:"100px" }}>
      <OriginalMusic />
      </Box>
      <Box sx={{ width: "100%", marginBottom:"100px" }}>
      <CopyMusic />
      </Box>
      {/* <Box sx={{ width: "100%", marginBottom:"100px" }}>
      <Lesson />
      </Box> */}
    </Box>
    </FeedinTransition>
  );
};

export default Lineup;
