import { Container } from "@mui/system";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import PageTitle from "./PageTitle";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import CustomizedAccordions from "./CustomizedAccordions";
import FeedinTransition from "../utils/feedinTransition";

const Cdt = styled.dt`
  text-align: left;
  margin-left: 10px;
`;

const items_hardware = [
  "Audio Interface : RME Babyface Pro fs",
  "PC : Windows 10",
  "DAW : Studio One 6 Professional",
  "Speaker : Yamaha MSP3",
  "Headphone : Audio-Technica ATH-M50x",
  "Keyboard : HHKB Professional HYBRID Type-S",
  "Mouse : Elecom HUGE Trackball",
  "Microphone : Shure SM58",
];

const items_instruments = [
  "Electric Guitar : FUJIGEN EOS-ASH-M/SBB",
  "Acoustic Guitar : YAMAHA LS-6",
  "Electric Bass : Bacchus WL4-FM CUSTOM Ⅲ BLU/OIL-MH",
  "MIDI Keyboard : Roland A-88 Mk2",
];

const items_vstinstruments = [
  "Drums : BFD3, Superior Drummer 3, Studio Drummer…",
  "Piano : The Grandeur, Ivory Ⅱ Studio Grands…",
  "Synthesizer : Serum, Massive, Sylenth1, Nexus…",
  "Strings : Spitfire Chamber Strings",
  "Brass : Chris Hein Horns Pro Complete",
  "Bass : MODO BASS",
  "Bundle : Komplete 12 Ultimate",
];

const items_vsteffects = [
  "Waves : Waves Horizon, Abbey Road Chambers,…",
  "iZotope : Ozone 11 Advanced, RX 8 Standard, VocalSynth 2,…",
  "Arturia : FX Collection 2",
  "Celemony : Melodyne 5 Assistant",
  "IK Multimedia : AmpliTube 4",
  "Plugin Alliance : Ampeg SVTVR Classic, Brainworx bx_saturator V2, Shadow Hills Masterring Compressor",
  "PositiveGrid : BIAS Amp 2, BIAS FX2",
  "Pulsar : Mu",
];

export default function About() {
  return (
    <FeedinTransition>
      <Container>
        <PageTitle>About</PageTitle>
        <Box
          sx={{
            width: { sm: "100%", md: "80%" },
            margin: "60px auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              width="150px"
              src="./images/uzuki_face.png"
              alt="uzuki_kanade"
            />
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "1.2rem", md: "2rem" },
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              メリッサ / 卯月奏
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "1.0rem", md: "1.2rem" },
                fontWeight: 300,
                textAlign: "center",
                marginBottom: "25px",
              }}
            >
              作曲家/ボカロP
            </Typography>
            <Paper
              elevation={3}
              sx={{
                bgcolor: "#fffaf0",
                padding: "20px",
                width: "90%",
                margin: "0 auto",
                textAlign: "center",
                lineHeight: "2rem",
                shadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",

              }}
            >
              <Typography variant="body1" sx={{lineHeight: "2rem"}}>
                1994年生まれ。宮城県出身。
                <br />
                小学校の頃からピアノを習い、中学生の頃から作曲を始める。
                <br />
                2011年からボカロPとして活動を開始。
                <br />
                2018年には歌い手の優莉・イラストレーターの笹本バターと同人音楽サークル「Alumina」を結成し、M3を拠点に音楽活動を始める。
                <br />
                2019年以降はVTuberへの楽曲提供を中心に活動しており、ホロライブの夏色まつりさんに提供した「君と眺める夏の花」はYoutubeで89万再生を記録している。
                <br />
                2023年からは新たなボカロP名義「卯月奏」としてSynthesizerVによるオリジナル曲を投稿している。
              </Typography>
            </Paper>
            <Box
              sx={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                lineHeight: "2rem",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "1.2rem", md: "2rem" },
                  fontWeight: 700,
                  textAlign: "center",
                  marginTop: "50px",
                  marginBottom: "25px",
                  padding: "0.5em",/*文字周りの余白*/
                  color: "#494949",/*文字色*/
                  background: "#fffaf4",/*背景色*/
                  borderLeft: "solid 5px #ffaf58",/*左線（実線 太さ 色）*/
                  borderRight: "solid 5px #ffaf58"/*左線（実線 太さ 色）*/
                }}
              >
                代表作
              </Typography>
              <Typography variant="body1">
              <ul>
                <li>君と眺める夏の花 / 夏色まつり(ホロライブ所属VTuber)</li>
                <li>あいらびゅ / 天使にはなれない(アイドルグループ)</li>
                <li>エンドロールの最後まで / 常世モコ(個人VTuber)</li>
                <li>
                  Everyday kawaii / メリッサ(ホロライブBGMコンテストにて入選)
                </li>
              </ul>
              </Typography>
            </Box>
            <Box
              sx={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                lineHeight: "2rem",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "1.2rem", md: "2rem" },
                  fontWeight: 700,
                  textAlign: "center",
                  marginTop: "50px",
                  marginBottom: "25px",
                  padding: "0.5em",/*文字周りの余白*/
                  color: "#494949",/*文字色*/
                  background: "#fffaf4",/*背景色*/
                  borderLeft: "solid 5px #ffaf58",/*左線（実線 太さ 色）*/
                  borderRight: "solid 5px #ffaf58"/*左線（実線 太さ 色）*/
                }}
              >
                得意な曲調は？
              </Typography>
              <Typography
                variant="body1">
              爽やかで可愛い曲が得意です。
              <br />
              ストリングスやブラスが入ったポップなバンドサウンドをよく作ります。
              <br />
              音楽理論の知識と丁寧なヒアリングで、ご依頼者様の想いを代弁したかのようにうまく音楽へ反映することが得意です。
              <br />
              VTuber様からのご依頼実績が多く、どの方からもご満足いただけています。
              <br />
              </Typography>
            </Box>
            <Box
              sx={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                lineHeight: "2rem",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "1.2rem", md: "2rem" },
                  fontWeight: 700,
                  textAlign: "center",
                  marginTop: "50px",
                  marginBottom: "25px",
                  padding: "0.5em",/*文字周りの余白*/
                  color: "#494949",/*文字色*/
                  background: "#fffaf4",/*背景色*/
                  borderLeft: "solid 5px #ffaf58",/*左線（実線 太さ 色）*/
                  borderRight: "solid 5px #ffaf58"/*左線（実線 太さ 色）*/
                }}
              >
                名義の使い分けは？
              </Typography>
              <Typography
                variant="body1">
              基本的にはほぼ全てメリッサ名義で楽曲提供やAluminaのようなクリエイター活動をしていく予定です。
              <br />お仕事のご依頼はメリッサ名義でお願いします。
              <br />
              卯月奏はボカロPとして自分の作りたい曲を作るためのアーティスト名義です。
              <br />
              楽曲提供などを多くいただくようになり、ご依頼者様の想いを代弁した曲や架空の女の子の心情を歌う曲を作ることが多くなってきました。
              <br />
              それ自体はとても有り難いですが、自分の心情を歌うアーティスト的な活動をしたいという想いがあり、そのために卯月奏という名義を作りました。
              <br />
              ボカロP活動はゆるっとやっていくので、見守っていただければ嬉しいです！
              </Typography>
            </Box>
            <Box
              sx={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "1.2rem", md: "2rem" },
                  fontWeight: 700,
                  textAlign: "center",
                  marginTop: "50px",
                  marginBottom: "25px",
                  padding: "0.5em",/*文字周りの余白*/
                  color: "#494949",/*文字色*/
                  background: "#fffaf4",/*背景色*/
                  borderLeft: "solid 5px #ffaf58",/*左線（実線 太さ 色）*/
                  borderRight: "solid 5px #ffaf58"/*左線（実線 太さ 色）*/
                }}
              >
                使用機材
              </Typography>
              <CustomizedAccordions
                hardware={items_hardware}
                inst={items_instruments}
                vstinst={items_vstinstruments}
                vsteffects={items_vsteffects}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </FeedinTransition>
  );
}
